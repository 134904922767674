import { BaseApp, reportWebVitals, TBaseAppProps } from '@front/master';
import { Manrope } from 'next/font/google';

import '../styles/globals.css';

const manropeFont = Manrope({ subsets: ['latin'] });

function App(props: TBaseAppProps): JSX.Element {
  return (
    <BaseApp
      gtmId='PCV8XZ62'
      roistatId='f500ee62d5e848446a10765f390f1447'
      className={manropeFont.className}
      {...props}
    />
  );
}

export { reportWebVitals };

export default App;
